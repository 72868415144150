import {LocationExplorer} from "@components/LocationExplorer/index";
import {useRouter} from "next/router";
import {useTranslation} from "ni18n";
import React from "react";
import {useTypedSelector} from "src/store";
import {selectSelectedRegion} from "src/store/slices/userLocation";
import {RegionSlug} from "src/store/types";

import {Pathname, v5Pages} from "../../_common/_constants";

export const EXCLUDED_PAGES: Pathname[] = [
  // Location Index
  v5Pages.locations,

  // Partner Pages
  v5Pages.alaskaToHawaii,
  v5Pages.covid19TravelClearance,

  // Service Maps
  v5Pages.covid19TestingCenters,
  v5Pages.fluVaccine,
  v5Pages.endocrinologyMap,
  v5Pages.mammogramMap,
  v5Pages.vaccineNearMe,

  // Healthpass
  v5Pages.healthPass,
  v5Pages.partners,

  // Care Discovery
  v5Pages.getCareSlug,
  v5Pages.testpage,

  v5Pages.careers,
  v5Pages.collegesOfFenway,
];

export const LocationExplorerContainer: React.FC = () => {
  const i18n = useTranslation();
  const {locations} = useTypedSelector(({config}) => config);
  const selectedRegion = useTypedSelector(selectSelectedRegion);

  const {pathname, query} = useRouter();
  const isLocationPage = pathname === v5Pages.clinicDetails;

  let currentLocationId;
  let initialRegion = selectedRegion;
  let isSlotTimeVisible = true;
  let headerText = i18n.t("Explore our locations");

  if (isLocationPage) {
    const currentLocation = locations.find(({slug}) => slug === query.slug);
    if (currentLocation) {
      currentLocationId = currentLocation.id;
      initialRegion = currentLocation.region.slug as RegionSlug;
    }

    isSlotTimeVisible = true;
    headerText = i18n.t("Explore other nearby locations");
  }

  if (!locations || locations.length === 0) return null;
  if (!selectedRegion) return null;

  return (
    <LocationExplorer
      locations={locations}
      headerText={headerText}
      initialRegionSlug={initialRegion}
      // @ts-expect-error TS2322: Type 'string | undefined' is not assignable to type 'string'.
      currentLocationId={currentLocationId}
      isSlotTimeVisible={isSlotTimeVisible}
    />
  );
};
