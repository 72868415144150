import Middle from "@components/Footer/Middle";
import MinimalFooter from "@components/Footer/MinimalFooter";
import Top from "@components/Footer/Top";
import {LinkProps} from "next/link";
import {useTranslation} from "ni18n";
import React from "react";
import ContentArea from "src/components/composition/ContentArea";
import Section from "src/components/composition/Section";
import {getCareSlugs} from "src/constants/getCareConstants";
import useMediaQuery from "src/useMediaQuery";

import {useTypedSelector} from "../../../store/index";
import {
  blogURL,
  facebookUrl,
  instagramUrl,
  linkedInUrl,
  occupationalHealthLink,
  supportLink,
  twitterUrl,
  v5Pages,
} from "../../_common/_constants";
import SmallMiddle from "./SmallMiddle";

type Props = {
  minimal?: boolean;
};

export type Item = LinkProps & {
  text: string;
};

export type ItemGroup = {
  id: string;
  header: string;
  items: Item[];
  isOnRight?: boolean;
  shouldMerge?: [number, string];
};

const Footer: React.FC<Props> = ({minimal}) => {
  const i18n = useTranslation();
  const {isMobile} = useTypedSelector(state => state.config);
  const sections: ItemGroup[] = [
    {
      id: "services",
      header: i18n.t("Services"),
      items: [
        {
          text: i18n.t("Primary Care"),
          href: {
            pathname: v5Pages.primaryCare,
          },
        },
        {
          text: i18n.t("Urgent Care"),
          href: {
            pathname: v5Pages.urgentCare,
          },
        },
        {
          text: i18n.t("Mental Health Care"),
          href: {
            pathname: v5Pages.virtualCareMental,
          },
        },
        {
          text: i18n.t("Virtual Care"),
          href: {
            pathname: v5Pages.virtualCare,
          },
        },
        {
          text: i18n.t("COVID Testing"),
          href: {
            pathname: v5Pages.getCareSlug,
            query: {
              slug: getCareSlugs.covidCare,
            },
          },
        },
        {
          text: i18n.t("Get Care"),
          href: {
            pathname: v5Pages.getCareRoot,
          },
        },
        {
          text: i18n.t("Insurance & Pricing"),
          href: {
            pathname: v5Pages.insurance,
          },
        },
      ],
    },
    {
      id: "company",
      header: i18n.t("Company"),
      items: [
        {
          text: i18n.t("Locations"),
          href: {
            pathname: v5Pages.locations,
          },
        },
        {
          text: i18n.t("About Us"),
          href: {
            pathname: v5Pages.about,
          },
        },
        {
          text: i18n.t("Careers"),
          href: {
            pathname: v5Pages.careers,
          },
        },
        {
          text: i18n.t("Press"),
          href: {
            pathname: v5Pages.press,
          },
        },
        {
          text: i18n.t("Blog"),
          href: blogURL,
        },
      ],
    },
    {
      id: "forBusiness",
      header: i18n.t("For Business"),

      items: [
        {
          text: i18n.t("Partners"),
          href: v5Pages.partners,
        },
        {
          text: "Carbon for Campus",
          href: v5Pages.carbonForCampus,
        },
        {
          text: i18n.t("Occupational Health"),
          href: occupationalHealthLink,
        },
        {
          text: i18n.t("COVID Employer Program"),
          href: v5Pages.covidReady,
        },
      ],
    },
    {
      id: "follow",
      header: i18n.t("Follow"),

      items: [
        {
          text: i18n.t("Instagram"),
          href: instagramUrl,
        },
        {
          text: i18n.t("Twitter"),
          href: twitterUrl,
        },
        {
          text: i18n.t("Facebook"),
          href: facebookUrl,
        },
        {
          text: i18n.t("LinkedIn"),
          href: linkedInUrl,
        },
      ],
    },
    {
      id: "support",
      header: i18n.t("Get Support"),
      shouldMerge: [2, i18n.t("Get in touch")],
      isOnRight: true,
      items: [
        {
          text: "support@carbonhealth.com",
          href: "mailto:support@carbonhealth.com",
        },
        {
          text: i18n.t("Support Center"),
          href: supportLink,
        },
      ],
    },
  ];

  const isSm = useMediaQuery("sm");

  return (
    <footer className={`pt20 pt10-md ${isMobile ? "brdt brd-gray200" : ""}`} id="footer">
      {!minimal && (
        <Section>
          <ContentArea>
            <>
              <Top key="footer-top" />
              {isSm ? (
                <SmallMiddle key="footer-small-middle" sections={sections} />
              ) : (
                <Middle key="footer-middle" sections={sections} />
              )}
            </>
          </ContentArea>
        </Section>
      )}
      <MinimalFooter />
    </footer>
  );
};

export default Footer;
